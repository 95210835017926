import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
function Contact() {
  return (
    <Layout>
      <Seo title="Voice To Text" />

      <section>
        <img
          src="https://i.ibb.co/FnNhFKX/image-2022-03-21-164426.png"
          alt=""
          className="w-3/6 mx-auto mb-2 mt-20  lg:mt-10 "
        />
        <div className=" p-2">
          <div className="container flex flex-col items-center px-4 py-8 pb-4 mx-auto text-center lg:pb-16 md:py-22 md:px-10 lg:px-32 text-coolGray-900">
            <h1 className="text-5xl text-[#33c3b0] leading-none sm:text-6xl xl:max-w-3xl ">
              Don't ever hesitate to reach.
            </h1>
            <p className="mt-6 mb-8 text-lg sm:mb-12 xl:max-w-3x ">
              We are super active on the platforms below. Send through your
              messages requesting Features or passing Feedback. We really value
              the time you've taken to reach out.
            </p>
            <div className="flex flex-wrap justify-center">
              <a
                href="https://twitter.com/hearme_aid"
                class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/80 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 rounded  px-8 py-3 m-2 text-lg text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55"
              >
                <svg
                  class="w-4 h-4 mr-2 -ml-1"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="twitter"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"
                  ></path>
                </svg>
                Reach Out
              </a>
              <a
                href="https://wa.link/8mo9vr"
                class="text-green-400 border border-2 border-green-300 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 rounded  px-8 py-3 m-2 text-lg text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55"
              >
                <svg
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="whatsapp"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 mr-2 -ml-1 text-lg"
                >
                  <path
                    d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                    fill="currentColor"
                  ></path>
                </svg>
                Reach Out
              </a>
            </div>
            <button
              type="button"
              class="text-white bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 rounded  px-8 py-3 m-4 text-lg  text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 mr-2 mb-2"
            >
              <svg
                class="w-4 h-4 mr-2 -ml-1"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="paypal"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4 .7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9 .7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"
                ></path>
              </svg>
              Support Project
            </button>
          </div>
        </div>
        <nav
          aria-label="breadcrumb"
          className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
        >
          <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
            <li className="flex items-center">
              <a
                rel="noopener noreferrer"
                href="/"
                title="Back to homepage"
                className="flex items-center hover:underline"
              >
                Home
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/know"
                className="flex items-center px-1 capitalize hover:underline"
              >
                About
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/started"
                className="flex items-center px-1 capitalize hover:underline"
              >
                Features
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/contact"
                className="flex items-center px-1 capitalize hover:underline font-bold "
              >
                Contact
              </a>
            </li>
          </ol>

          <footer className="animate-pulse text-center text-sm">
            © {new Date().getFullYear()}, An Idea by
            {` `}
            <a className="text-center text-sm" href="https://www.chakama.co.zw">
              George Chakama
            </a>
          </footer>
        </nav>
      </section>
    </Layout>
  )
}
export default Contact
